.register{
    background: -webkit-linear-gradient(left, #3931af, #00c6ff);
    margin-top: 3%;
    padding: 3%;
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}
.register-left input{
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
}
.register-right{
    background: #f8f9fa;
    border-top-left-radius: 10% 50%;
    border-bottom-left-radius: 10% 50%;
}
.register-left img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.register-left p{
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}
.register .register-form{
    padding: 10%;
    margin-top: 10%;
}
.btnRegister{
    float: right;
    // margin-top: 10%;
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    background: #0062cc;
    color: #fff;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
}
.register .nav-tabs{
    margin-top: 3%;
    border: none;
    background: #0062cc;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}
.register .nav-tabs .nav-link{
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
    border: none;
}
.register .nav-tabs .nav-link.active{
    width: 100px;
    color: #0062cc;
    border: 2px solid #0062cc;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.register-heading{
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
}

.dob{
	position: relative;
    top: 14px;
}

.MyCardElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .MyCardElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .MyCardElement--invalid {
    border-color: #fa755a;
  }
  
  .MyCardElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .stripe-form{
    background: white;
    padding: 12px;
  }

  .custom-loader{
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    transform: translateY(-50%);
    height: 100%;
    background: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;

  }

.mainsection {
    position: relative;
    float: left;
    width: 100%;
    padding: 23px 42px;
}
.main-container{
    display: block;
}

body{
    background-image: url('https://www.continuingedseminar.com/script/images/bg.jpg');
}

/*========header classes=====**/
.header {
    // width: 1000px;
    height: 113px;
    // float: left;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3931af+0,2b88d9+50,00c6ff+100 */
    background: #3931af; /* Old browsers */
    background: -moz-linear-gradient(left,  #3931af 0%, #2b88d9 50%, #00c6ff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #3931af 0%,#2b88d9 50%,#00c6ff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #3931af 0%,#2b88d9 50%,#00c6ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3931af', endColorstr='#00c6ff',GradientType=1 ); /* IE6-9 */

    // background: -webkit-linear-gradient(left, #3931af, #00c6ff);
    //background-image: url('https://www.continuingedseminar.com/script/images/head.png');
    .logo {
        // width: 318px;
        height: 86px;
        float: left;
        margin-left: 20px;
        margin-top: 7px;
        img{
            max-width: 255px;
        }
    }
    .menu {
        padding-left: 300px;
        padding-top: 8px;
        width: auto;
        height: 41px;
        float: left;
        margin-top: 49px;
        margin-left: 200px;
        ul {
            margin: 0px;
            padding: 0px;
            float: left;
            list-style: none;
            display: block;
     
        }
        li {
            color: #fff;
            font-size: 13px;
            font-family: 'Denk One', sans-serif;
            list-style-type: none;
            float: left;
            padding: 20px 0px 20px 0px;
            margin-left: 0px;
            display: list-item;
            text-align: -webkit-match-parent;

            a {
                color: #fff;
                text-decoration: none;
                padding: 10px 15px 10px 15px;
                border-radius: 5px;
            }
        }
    }
}

header{
    .container{
        // width: 1000px;
        //height: 113px;
        // float: left;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3931af+0,2b88d9+50,00c6ff+100 */
        background: #3931af; /* Old browsers */
        background: -moz-linear-gradient(left,  #3931af 0%, #2b88d9 50%, #00c6ff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #3931af 0%,#2b88d9 50%,#00c6ff 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #3931af 0%,#2b88d9 50%,#00c6ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3931af', endColorstr='#00c6ff',GradientType=1 ); /* IE6-9 */

        // background: -webkit-linear-gradient(left, #3931af, #00c6ff);
        //background-image: url('https://www.continuingedseminar.com/script/images/head.png');
        img{
            max-width: 280px;
        }
        ul {
            margin: 0px;
            padding: 0px;
            float: right;
            list-style: none;
            display: block;
     
        }
        li {
            color: #fff;
            font-size: 13px;
            font-family: 'Denk One', sans-serif;
            list-style-type: none;
            float: left;
            padding: 20px 0px 20px 0px;
            margin-left: 0px;
            display: list-item;
            text-align: -webkit-match-parent;

            a {
                color: #fff;
                text-decoration: none;
                padding: 10px 15px 10px 15px;
                border-radius: 5px;
            }
        }
    }
}

.nav{
        background: #3931af; /* Old browsers */
        background: -moz-linear-gradient(left,  #3931af 0%, #2b88d9 50%, #00c6ff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #3931af 0%,#2b88d9 50%,#00c6ff 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #3931af 0%,#2b88d9 50%,#00c6ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3931af', endColorstr='#00c6ff',GradientType=1 ); /* IE6-9 */
        img{
            max-width: 280px;
        }
        .link {
            //font-weight: bold;
            color: #fff;
            text-decoration: none;
            background-color: transparent;
        }
}
.navbar-light .navbar-nav .nav-link{
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    background: #199de6;
    border: 1px solid #e0eef3;
}

.flex-direction{
    flex-direction: row-reverse;
}
main{
    .conainer{
        background-color: #e7f7ff;
    }
}

.footer{
    background: black;
    color: white;
    text-align: center;
    .footer_txt_copy{
        padding: 38px;
    }
}


.end {
    width: 1000px;
    height: 95px;
    float: left;
    background-color: #000000;
    .footer_txt {
        width: 450px;
        height: auto;
        float: left;
        margin-left: 262px;
        margin-top: 9px;
        .footer_txt_menu {
            width: auto;
            height: 20px;
            float: left;
        }
        .footer_txt_copy {
            width: 410px;
            height: auto;
            float: left;
            color: #FFFFFF;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            padding-left: 20px;
        }
    }
}

.inner {
    width: 1000px;
    height: auto;
    float: left;
    background-color: #e7f7ff;
    padding-bottom: 20px;
}

.padding-30{
    padding: 30px;
}
.seminal-list{
    border: 2px solid #3e73bb;
    border-top: 3px solid #3e73bb;
}

.seminar-div{
    border-radius: 6px;
    padding: 20px;
    border: 1px solid #717d9c;
    background: #ecf4f7;
    border-top: 2px solid #717d9c;
    .center-div{
        border: 2px solid #2c65cb;
        border-radius: 10px;
        max-width: 203px;
        width: 100%;
        height: 44px;
        line-height: 44px;
        text-align: center;
        display: inline-block;
        a {
            font-weight: bold;
            color: #6485a9;
            text-decoration: none;
            background-color: transparent;
        }
    }
    h2{
        color:#01bffa;
        padding-top: 12px;
    }
}

.sidebar-index{
    padding: 5px;
    font-weight: 300;
    border: 2px solid #9a9894;
    border-radius: 10px;
    background: #e0eef3;
    
    .nav {
        background:none;
        background: none;
        .nav-link {
            display: flex;
            // padding: 0.0rem 2rem;
            padding:none !important;
            padding-left: 26px;
            font-size: 11px;
            font-weight: 400;
            span{
                display: inline-table;
            }
        }
        // .nav-link.active, .nav-pills .show>.nav-link {
        //     color: rgb(0, 0, 0);
        //     background-color:#ffffff;
        // }
        a{
            padding: 2px;
            color: #0c0c0c;
            text-decoration: none;
            background-color: transparent;
        }
    }
}

.back-lesson{
    text-align: center;
    font-weight: bold;
    color: #1f94e1;
}


.row-padding{
    padding: 37px;
    background: white;
}
.col-3-border{
    // border-right: 2px solid #d0cece;
    .div-head{
        text-align: center;
        padding-top: 7px;
        padding-bottom: 16px;
        max-width: 100%;
    }
    .span-head{
        padding: 8px;
        background: #75bcd6;
        border: 1px solid #75bcd6;
        border-radius: 6px;
        color: white;
        font-weight: bold;
        max-width: 100%;
        display: block;
    }
}

.min-height{
    min-height: 100vh;
    background: #e0eef3;
    border: 2px solid #9e9e95;
    border-radius: 8px;
    // p{
    //     display: flex;
    // }
    // span{
    //     display: inline-table;
    //     margin-right: 5px;
    // }
}
.width-100{
    width:100%;
}
.stress{
    img{

    }
}
.content{
    padding-bottom: 15px;
}
.test-head{
    font-size: 13px;
    font-weight: bold;
}
.option{
    position: relative;
    left: 5%;
}
.list-style{
    li{
        list-style: none;
    }
}
.padding-10{
    padding: 10px;
}
.inner-payment-div{
    max-width: 70%;
    position: relative;
    left: 19%;
}
.name {
    position: absolute;
    bottom: 49%;
    left: 41%;
    font-size: 31px;
    font-style: italic;
    color: #575752;
    font-weight: 500;
}
.main-certificate img {
    position: relative;
    width: 100%;
    float: left;
}
.seminar-text {
    position: absolute;
    bottom: 46%;
    left: 46%;
    font-size: 16px;
    color: #7d7d7a;
    font-weight: 700;
    letter-spacing: -1px;
}
.margin-10{
    margin-bottom: 10px;
}

.intro-index{
    list-style: none;
    position: relative;
    left: 25%;
    line-height: 21px;
    width: 308px;
    padding: 17px;
    color: #5c86c6;
    font-weight: bold;

    li{
        padding: 2px 10px;
        border: 1px solid #5c86c6;
        display: flex;
    }
}
.nav-link{
    padding: 0rem 0rem;
}
.img-question{
    width: 100px;
    height: 100px;
    margin-left: 40px;
    margin-bottom: 15px;
}
.section-color{
    color: #25c0fa;;
}

.text-bold{
    font-weight: bold;;
}
.complete-lesson{
    background-color:#b4ec76
}
.certificate-note{
    font-size: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}
.certificate-note-position{
    position: relative;
}

.disabled {
    pointer-events: none;
    cursor: default;
  }
.min-time{
    color: black;
    font-size: 10px;
    font-weight: bold;
    
}  

.time-consume{
    position: relative;
    top: 28px;
    left: 81%;
}
.btn-modal{
    color: #065678;
    background-color: #ffffff;
    border-color: #007bff;
}
.btn-modal:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.color-wh{
    color: #fff;
}